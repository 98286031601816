import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import fordStyles from "./../orderDetails/NAOrderDetailsNewVersion.module.scss";
import { withTranslation } from "react-i18next";
import CustomerInformation from "../orderDetails/CustomerInformation";
import ConsumerDeposit from "../orderDetails/ConsumerDeposit";
import HasPermissionTo from "../../../common/HasPermissionTo";
import TradeIn from "../../tradeIn/TradeInWrapper";
import Charging from "./../purchaseDetails/addOns/Charging";
import {
  FINANCE_PRODUCT_TYPE,
  FINANCED,
  ORDER_VIN_UNAVAILABLE,
  OWNED,
  PDS_STATUS_ACCEPT,
  PDS_STATUS_READY,
  PDS_STATUS_REJECT,
  PURCHASE_REQUEST_STATUS,
  PIPELINE,
  PURCHASE_TYPES,
  INITIATED,
  generateSmartVincentLink,
} from "../../../common/Constants";
import PurchaseAccessories from "./../purchaseDetails/addOns/PurchaseAccessories";
import ProtectionPlan from "./../purchaseDetails/protectionPlan/ProtectionPlan";
import PricingSummary from "./../purchaseDetails/addOns/PricingSummary";
import VehicleDetails from "../orderDetails/VehicleDetails";
import PaymentMethod from "./../purchaseDetails/paymentMethod/PaymentMethod";
import CustomerReservationsClient from "../../../clients/CustomerReservationsClient";
import OffersAndIncentives from "./../purchaseDetails/offersAndIncentives/OffersAndIncentives";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import UpperCaseText from "../../../utils/UpperCaseText";
import DealerInformation from "../orderDetails/DealerInformation";
import { handleBack } from "../../../utils/GoBack";
import NADealerNotifications from "../notifications/NADealerNotifications";
import NADealerNotificationsClient from "../../../clients/NADealerNotificationsClient";
import DealerNotes from "../orderDetails/dealerNotes/DealerNotes";
import ModelEOrderInformation from "./ModelEOrderInformation";
import CustomerContracts from "./CustomerContracts";
import EMPButton from "../../../shared/components/EMPButton/EMPButton";
import Delivery from "./Delivery";
import AcceptRejectDetails from "./AcceptRejectDetails";
import DetailsHeader from "../common/DetailsHeader";
import MotionChevronLeft from "../../../shared/Icons/MotionChevronLeft";
import SubNav from "../../../shared/subnav/SubNav";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useAnalytics } from "./ModelEOrderDetailsAnalytics";

const useErrorStyles = makeStyles({
  root: {
    fontFamily: "'Ford Antenna Regular', 'FordAntennaRegular', sans-serif",
  },
  body1: {
    fontSize: "16px",
    color: "#000000",
  },
  colorError: {
    fontFamily: "'FordAntennaBold', sans-serif",
    fontSize: "12px",
    backgroundColor: "rgba(246, 229, 231, 1.00)",
    color: "#4a4a4a",
    padding: "8px 48px",
    lineHeight: "20px",
  },
});

const ModelENotifications = ({
  orderId,
  vin,
  user,
  renderedCommonId,
  displayNotifications,
  status,
  systemStatus,
  showCustomerFollowupNotification,
  isFederalTaxCreditEligible,
  financeStatus,
  t,
}) => {
  const classes = useErrorStyles();
  const queryClient = useQueryClient();
  const invalidNotificationIds = [];
  const customNotifications = [];
  if (isFederalTaxCreditEligible) {
    customNotifications.push({
      params: {
        NotificationMsg: t("ModelEOrders.iraTaxCreditNotification"),
      },
      custom: true,
      showMoreInfoLink: true,
      notificationType: "NOTIFICATION",
    });
  }
  if (systemStatus === PDS_STATUS_ACCEPT) {
    customNotifications.push({
      params: {
        NotificationMsg: t(
          "ModelEOrders.preDeliverySummaryAcceptedNotification"
        ),
      },
      custom: true,
      notificationType: "NOTIFICATION",
    });
  } else if (systemStatus === PDS_STATUS_REJECT) {
    customNotifications.push({
      params: {
        NotificationMsg: t(
          "ModelEOrders.preDeliverySummaryRejectedNotification"
        ),
      },
      custom: true,
      notificationType: "NOTIFICATION",
    });
  } else if (systemStatus === PDS_STATUS_READY) {
    customNotifications.push({
      params: {
        NotificationMsg: t("ModelEOrders.preDeliverySummaryReadyNotification"),
      },
      custom: true,
      notificationType: "NOTIFICATION",
    });
  }

  if (showCustomerFollowupNotification) {
    customNotifications.push({
      params: {
        NotificationMsg: t("ModelEOrders.customerFollowupNotification"),
      },
      custom: true,
      notificationType: "ACTION",
    });
  }

  customNotifications.push({
    params: {
      NotificationMsg: t("ModelEOrders.smartVincentNotification"),
    },
    custom: true,
    showMoreInfoLink: true,
    customLink: generateSmartVincentLink(vin !== undefined ? vin : null),
    customLinkText: t("ModelEOrders.smartVincentButtonText"),
    notificationType: "NOTIFICATION",
  });

  if (financeStatus === INITIATED) {
    customNotifications.push({
      params: {
        NotificationMsg: t("ModelEOrders.routeOneCheckNotification"),
      },
      custom: true,
      showMoreInfoLink: true,
      customLink: "https://www.routeone.net",
      customLinkText: t("ModelEOrders.routeOneButtonText"),
      notificationType: "NOTIFICATION",
    });
  }

  function updateNotification(data) {
    const notifications = [];
    data?.data?.ordersData.forEach((notification) => {
      if (notification.notificationCode === ORDER_VIN_UNAVAILABLE) {
        if (UpperCaseText(status) !== PURCHASE_REQUEST_STATUS.CANCELED) {
          notification.params.NotificationMsg = t(
            "ModelEOrders.vinUnavailableNotification"
          );
          notification["custom"] = true;
          notification.notificationType = "ACTION";
          notifications.push(notification);
        } else {
          // this notification will get deleted
          invalidNotificationIds.push(notification.uid);
        }
      } else {
        notifications.push(notification);
      }
    });
    return notifications;
  }

  const {
    isLoading,
    data: notifications,
    isError,
  } = useQuery({
    queryKey: ["notifications", orderId],
    queryFn: async () =>
      NADealerNotificationsClient.getNotifications(
        user,
        renderedCommonId.split("|").join("%7C"),
        orderId
      ),
    enabled: !!displayNotifications,
    select: updateNotification,
    onSuccess: () => {
      invalidNotificationIds.forEach((id) => deleteNotification.mutate(id));
    },
  });

  const deleteNotification = useMutation({
    mutationFn: (notificationId) =>
      NADealerNotificationsClient.deleteNotification(user, notificationId),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: ["notifications"] }),
  });

  if (isLoading) {
    return <Skeleton variant="rect" animation="wave" height={41} />;
  }

  if (isError) {
    return (
      <Box>
        <Typography variant="body1" classes={{ ...classes }} color="error">
          {t("ModelEOrders.notificationLoadingError")}
        </Typography>
      </Box>
    );
  }
  const combinedNotifications = [...notifications, ...customNotifications];
  return (
    <NADealerNotifications
      notifications={combinedNotifications}
      user={user}
      t={t}
      deleteNotification={(id) => deleteNotification.mutate(id)}
      orderId={orderId}
    />
  );
};

const ModelEOrderDetails = ({
  orderId,
  commonId,
  user,
  fromCrc,
  fromFieldView,
  history,
  t,
  localizedInfo,
  orderDetail,
  hideOrShow,
}) => {
  const [expandAll, setExpandAll] = useState(false);
  const [customerFollowupNotification, setCustomerFollowupNotification] =
    useState(false);
  const renderedCommonId =
    commonId || orderDetail?.dealerInfo?.commonId.split("|").join("%7C");
  const renderedOrderId = orderId || orderDetail?.customerReservation?.id;

  const queryClient = useQueryClient();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function getBackRoute() {
    if (fromCrc) {
      handleBack(fromCrc, history);
    } else {
      handleBack(false, history);
    }
  }

  const {
    isInitialLoading,
    isFetching,
    data: order,
    isError,
    error,
  } = useQuery({
    queryKey: ["order", renderedOrderId],
    queryFn: async () => {
      const { data } = await CustomerReservationsClient.fetchOrderDetails(
        user,
        orderId,
        commonId,
        false,
        user.lang,
        null
      );
      return data;
    },
    enabled: !!orderId && !orderDetail,
    initialData: orderDetail && orderDetail,
  });

  const res = order?.customerReservation;

  const { data: pricingStack } = useQuery({
    queryKey: ["pricingStack", renderedOrderId],
    queryFn: () =>
      CustomerReservationsClient.getPricingStack(
        user,
        renderedOrderId,
        null,
        renderedCommonId,
        user.lang
      ),
    enabled: !!res,
  });

  const isAutoAccepted = [
    FINANCE_PRODUCT_TYPE.FINANCE,
    FINANCE_PRODUCT_TYPE.LEASE,
    FINANCE_PRODUCT_TYPE.FLEX_BUY,
  ].includes(order?.customerReservation?.financeDataQuote?.financeProductType);

  const acceptRejectMessage = (acceptRejectStatus) => {
    if (isAutoAccepted) {
      return "autoAccepted";
    } else {
      return acceptRejectStatus;
    }
  };
  const vehicleInfo = {
    namePlate: order?.customerReservation?.nameplate,
    modelYear: order?.customerReservation?.modelYear,
    vin: order?.customerReservation?.vinNumber,
  };

  const continueAtDealer =
    order?.customerReservation?.purchaseInfo?.purchaseType ===
    PURCHASE_TYPES.CONTINUE_AT_DEALER;

  const isDownPaymentEnabled =
    order?.customerReservation?.purchaseInfo?.isDownPaymentEnabled;

  const displayTradeInAccordion =
    !!order?.tradeIn &&
    Object.prototype.hasOwnProperty.call(
      user.permissions.rolePermissions,
      "tradeIn:view"
    );

  const orderDepositAmount =
    order?.customerReservation?.orderPaymentInfo?.depositAmount;

  const purchaseDepositAmount =
    order?.customerReservation?.purchaseDeposit?.depositAmount;

  const displayConsumerDepositAccordion =
    (orderDepositAmount || purchaseDepositAmount || isDownPaymentEnabled) &&
    !continueAtDealer;

  const purchaseRequestAccepted =
    order?.customerReservation?.purchaseInfo?.acceptRejectStatus ===
      "accepted" ||
    (order?.customerReservation?.purchaseInfo?.purchaseRequestDealerAction &&
      order?.customerReservation?.purchaseInfo?.purchaseRequestDealerAction
        .dealerAction === "purchaserequestaccepted");

  const disableDealerActionsFlagForPurchaseRequests =
    order?.customerReservation?.vehicleSoldOrCancelledPast30Days;

  const vehicleSoldDateBefore30Days =
    !disableDealerActionsFlagForPurchaseRequests &&
    order?.customerReservation?.vehicleStatusCode === "VPHD";

  const vehicleCancelDateBefore30Days =
    !disableDealerActionsFlagForPurchaseRequests &&
    order?.customerReservation?.purchaseInfo?.acceptRejectStatus ===
      "cancelled";

  const isTradeInLinksEnabled =
    purchaseRequestAccepted ||
    vehicleSoldDateBefore30Days ||
    vehicleCancelDateBefore30Days;

  const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  };

  const getFullName = (value) => {
    let fullName = "";
    if (value) {
      if (value.firstName) fullName += value.firstName;
      if (value.lastName)
        fullName.length > 0
          ? (fullName += " " + value.lastName)
          : (fullName += value.lastName);
    }
    return fullName;
  };

  useAnalytics(renderedOrderId, user);

  return (
    <div className={`pageWrapper ${fordStyles.whiteBg}`}>
      <SubNav
        id="subNav"
        title={t("GlobalNavBar.dashboard")}
        user={user}
        isDealer
      />

      <HasPermissionTo
        perform={["USOrderDetailsNotifications:view"]}
        permissions={user.permissions.rolePermissions}
        condition={!isInitialLoading && !isFetching && !isError}
        render={() => (
          <ModelENotifications
            orderId={renderedOrderId}
            vin={order?.customerReservation?.vinNumber}
            renderedCommonId={renderedCommonId}
            user={user}
            displayNotifications={
              order?.customerReservation?.displayNotifications
            }
            customerFollowupNotification={customerFollowupNotification}
            status={
              order?.customerReservation?.purchaseInfo?.acceptRejectStatus
            }
            isFederalTaxCreditEligible={
              order?.customerReservation?.isFederalTaxCreditEligible
            }
            systemStatus={order?.preDeliverySummary?.systemStatus}
            financeStatus={order?.customerReservation?.financeStatus}
            t={t}
          />
        )}
      />

      <Grid
        container
        className={fordStyles.componentContainer}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <EMPButton
            buttonText={t("PurchaseDetails.backToList")}
            testId="backButton"
            buttonStyle="text"
            leftIcon={<MotionChevronLeft />}
            onClick={() => getBackRoute()}
          ></EMPButton>
        </Grid>
        <Grid item>
          <IconButton
            aria-label="Print page content"
            id="printButton"
            data-testid="printButton"
            onClick={() => window.print()}
            edge="end"
            disableRipple
          >
            <PrintOutlinedIcon className={fordStyles.printIcon} size="small" />
          </IconButton>
        </Grid>
      </Grid>

      {isInitialLoading || isFetching ? (
        <Box mb={2} className={fordStyles.componentContainer}>
          <Grid container>
            <Grid item xs={12}>
              <Box mb={5}>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height={80}
                  width={375}
                />
              </Box>
              <Box mb={5}>
                <Skeleton variant="rect" animation="wave" height={90} />
              </Box>
              <Box mb={3}>
                <Skeleton variant="rect" animation="wave" height={400} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : isError ? (
        "error..." + error
      ) : (
        <>
          <div
            className={`${fordStyles.componentContainer} ${fordStyles.customerHeading}`}
          >
            <DetailsHeader
              user={user}
              customerReservation={order.customerReservation}
              customerInfo={order.customerInfo}
            ></DetailsHeader>
          </div>

          <Grid container className={fordStyles.componentContainer}>
            <Grid item xs={12}>
              <AcceptRejectDetails
                user={user}
                vin={order?.customerReservation?.vinNumber}
                paymentType={order?.customerReservation?.paymentType}
                orderId={orderId}
                todayDate={order?.customerReservation?.todayDate}
                currentStatus={acceptRejectMessage(
                  order?.customerReservation?.purchaseInfo?.acceptRejectStatus
                )}
                dealerId={order?.customerReservation?.dealerId}
                date={
                  order?.customerReservation?.purchaseInfo?.dealerActionDate
                }
                orderCreatedDate={new Date(
                  order?.customerReservation?.orderConfirmedDate
                ).toISOString()}
                t={t}
              />
            </Grid>
          </Grid>

          <Grid container className={fordStyles.componentContainer}>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <Delivery
                orderId={renderedOrderId}
                customerInfo={order.customerInfo}
                vehicleInfo={vehicleInfo}
                user={user}
                setCustomerFollowupNotification={(value) =>
                  setCustomerFollowupNotification(value)
                }
                transportError={(err) => console.log(`Error: ${err}`)}
              />
            </Grid>
          </Grid>

          <div
            className={`customerOrderDetails ${fordStyles.componentContainer}`}
          >
            <Grid
              container
              justifyContent="flex-end"
              className={fordStyles.expandContainer}
            >
              <Grid item onClick={() => setExpandAll(!expandAll)}>
                {`${expandAll ? "Collapse" : "Expand"} all rows`}
              </Grid>
            </Grid>
            <div className={fordStyles.expansionBox}>
              <ModelEOrderInformation
                user={user}
                continueAtDealer={continueAtDealer}
                customerReservation={order.customerReservation}
                expandAccordian={expandAll}
              />

              <CustomerInformation
                user={user}
                customerReservation={order.customerReservation}
                customerInfo={order.customerInfo}
                formatPhoneNumber={(phoneNumber) =>
                  formatPhoneNumber(phoneNumber)
                }
                fromPurchaseDetails={true}
                getFullName={(value) => getFullName(value)}
                expandAccordian={expandAll}
              />

              {(fromCrc || fromFieldView) && (
                <DealerInformation
                  user={user}
                  dealerInfo={order?.dealerInfo}
                  expandAccordian={expandAll}
                />
              )}

              <VehicleDetails
                user={user}
                customerReservation={order?.customerReservation}
                vehicleFeatures={
                  order.customerReservation
                    ? order.customerReservation.vehicleNAFeatures
                    : {}
                }
                dealerInfo={order.dealerInfo}
                expandAccordian={expandAll}
              />
              <HasPermissionTo
                perform={["dealerNotesView"]}
                permissions={user.permissions.rolePermissions}
                render={() => (
                  <DealerNotes
                    user={user}
                    orderId={orderId}
                    expandAccordion={expandAll}
                  />
                )}
              />

              <CustomerContracts
                t={t}
                customerContracts={
                  order?.customerReservation?.customerContracts
                }
                preDeliverySummary={order?.preDeliverySummary}
                expandAccordian={expandAll}
                docUploadDataStatus={
                  order?.customerReservation?.docUploadDataStatus
                }
              />
            </div>
          </div>

          <Grid container className={fordStyles.newOrdersDiv}>
            <Grid item xs={12} className={fordStyles.leftPadding}>
              <span>{t("PurchaseDetails.addOnsAndPricing")}</span>
            </Grid>
          </Grid>
          {order?.customerReservation?.spotBuy &&
          order?.customerReservation?.subJourneyType !== PIPELINE ? (
            <div className={fordStyles.componentContainer}>
              <PricingSummary
                user={user}
                isModelEOrder
                isDownPaymentEnabled={isDownPaymentEnabled}
                paymentType={order?.customerReservation?.paymentType}
                customerReservation={order?.customerReservation}
                pricingStack={pricingStack}
                isOrder={
                  UpperCaseText(order?.customerReservation?.orderType) ===
                  "ORDER"
                }
                isPurchaseRequest
                amountFinanced={
                  order?.customerReservation?.financeDataQuote
                    ?.amountFinanced ||
                  order?.customerReservation?.financeDataQuote
                    ?.totalAmountFinancedStandard
                }
                expandAccordian={expandAll}
              />
            </div>
          ) : (
            <>
              {order?.customerReservation?.subJourneyType !== PIPELINE && (
                <>
                  <div className={fordStyles.componentContainer}>
                    <div className={fordStyles.expansionBox}>
                      <Charging
                        user={user}
                        localizedInfo={localizedInfo}
                        customerReservation={order.customerReservation}
                        expandAccordian={expandAll}
                      />
                    </div>
                  </div>

                  <div className={fordStyles.componentContainer}>
                    <div className={fordStyles.expansionBox}>
                      <PurchaseAccessories
                        user={user}
                        accessories={order?.customerReservation?.allAccessories}
                        expandAccordian={expandAll}
                      />
                    </div>
                  </div>

                  <div className={fordStyles.componentContainer}>
                    <ProtectionPlan
                      user={user}
                      protectionPlansData={
                        order?.customerReservation?.protectionPlansData
                      }
                      expandAccordian={expandAll}
                    />
                  </div>

                  <div className={fordStyles.componentContainer}>
                    {pricingStack && (
                      <OffersAndIncentives
                        user={user}
                        fromPurchaseDetails
                        pricingStack={pricingStack}
                        expandAccordian={expandAll}
                        customerReservation={order?.customerReservation}
                      />
                    )}
                  </div>
                </>
              )}

              <div className={fordStyles.componentContainer}>
                <PricingSummary
                  user={user}
                  isModelEOrder
                  isDownPaymentEnabled={isDownPaymentEnabled}
                  paymentType={order?.customerReservation?.paymentType}
                  customerReservation={order?.customerReservation}
                  pricingStack={pricingStack}
                  isOrder={
                    UpperCaseText(order?.customerReservation?.orderType) ===
                    "ORDER"
                  }
                  isPurchaseRequest
                  amountFinanced={
                    order?.customerReservation?.financeDataQuote
                      ?.amountFinanced ||
                    order?.customerReservation?.financeDataQuote
                      ?.totalAmountFinancedStandard
                  }
                  expandAccordian={expandAll}
                />
              </div>

              <div className={fordStyles.componentContainer}>
                <PaymentMethod
                  user={user}
                  isModelEOrder
                  customerReservation={order?.customerReservation}
                  pricingStack={pricingStack}
                  financeProductType={order?.customerReservation?.paymentType}
                  authorizedBelow={isDownPaymentEnabled}
                  isDownPaymentEnabled={
                    isDownPaymentEnabled ||
                    vehicleSoldDateBefore30Days ||
                    vehicleCancelDateBefore30Days
                  }
                  expandAccordian={expandAll}
                />
              </div>

              {(displayTradeInAccordion || displayConsumerDepositAccordion) && (
                <Grid container className={fordStyles.newOrdersDiv}>
                  <Grid item xs={12} className={fordStyles.leftPadding}>
                    <span>{t("PurchaseDetails.dealerActions")}</span>
                  </Grid>
                </Grid>
              )}

              {displayTradeInAccordion && (
                <div className={fordStyles.componentContainer}>
                  <div className={fordStyles.expansionBox}>
                    <TradeIn
                      token={user.token}
                      user={user}
                      refreshReservationDetailsWithNotifications={() =>
                        queryClient.invalidateQueries()
                      }
                      isOrderCancelled={() => {}}
                      status={order.customerReservation.status}
                      tradeInDetails={order.tradeIn}
                      isModelEOrder
                      linkEnabled={
                        order.tradeIn.ownershipType === FINANCED ||
                        order.tradeIn.ownershipType === OWNED
                          ? isTradeInLinksEnabled &&
                            order.tradeIn.appraisalAmount
                          : isTradeInLinksEnabled
                      }
                      disableDealerActionsFlagForPurchaseRequests={
                        disableDealerActionsFlagForPurchaseRequests
                      }
                      localizedInfo={localizedInfo}
                      customerReservation={order.customerReservation}
                      expandAccordian={expandAll}
                      hideOrShow={hideOrShow}
                    />
                  </div>
                </div>
              )}

              {displayConsumerDepositAccordion && (
                <div className={fordStyles.componentContainer}>
                  <div className={fordStyles.expansionBox}>
                    <ConsumerDeposit
                      user={user}
                      hideOrShow={hideOrShow}
                      isPurchaseOrder
                      isModelE
                      customerReservation={order.customerReservation}
                      refreshReservationDetailsWithNotifications={() =>
                        queryClient.invalidateQueries()
                      }
                      isDownPaymentEnabled={isDownPaymentEnabled}
                      disableDealerActionsFlagForPurchaseRequests={
                        disableDealerActionsFlagForPurchaseRequests
                      }
                      expandAccordian={expandAll}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default withTranslation("emp")(ModelEOrderDetails);
