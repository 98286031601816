import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import UpperCaseText from "../../../../utils/UpperCaseText";
import {
  APPROVED,
  CONDITIONED_CREDIT_APP,
  DENIED_CREDIT_APP,
  DENIED_CREDIT_APP_ERROR,
  FINANCE_PRODUCT_TYPE,
  FINANCE_PROVIDER,
  INITIATED,
  PENDING_CREDIT_APP,
  PIPELINE,
  PURCHASE_INFO_CONTRACT_STATUS,
  PURCHASE_TYPES,
  THIRD_PARTY_FINANCING,
} from "../../../../common/Constants";
import {
  IS_FORD_BRAND,
  IS_USA_MARKET,
  NOTNULL_OR_NOTUNDEFINED,
} from "../../../../utils/EmpUtil";
import LowerCaseText from "../../../../utils/LowerCaseText";
import { StyledTableRow } from "../../../../shared/Tables/TableRow";
import {
  StyledExpansionPanel,
  StyledExpansionPanelSummary,
} from "../../../../shared/ExpansionPanels/ExpansionPanel";
import { formatPriceValue } from "../../../../utils/FormatPrice";
import StyledTableRowWithToolTip from "../../../../shared/Tables/TableRowWithToolTip";
import SharedToolTip from "../../../../shared/sharedToolTip/SharedToolTip";
import applicationStatus from "../../../../utils/GetApplicationStatus";
import LeasePaymentMethod from "./LeasePaymentMethod";
import FlexBuyPaymentMethod from "./FlexBuyPaymentMethod";
import fordSharedStyles from "../../orderDetails/NAOrderDetailsNewVersion.module.scss";
import lincolnSharedStyles from "../../orderDetails/NAOrderDetailsNewVersionLincoln.module.scss";
import crStylesFord from "../../crStyles";
import crStylesLincoln from "../../crStylesLincoln";
import fordStyles from "./PaymentMethod.module.scss";
import lincolnStyles from "./PaymentMethodLincoln.module.scss";

class PaymentMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentMethodExpanded: false,
    };

    this.financeProductType = LowerCaseText(this.props.financeProductType);
    this.purchaseType =
      this.props.customerReservation &&
      this.props.customerReservation.purchaseInfo &&
      this.props.customerReservation.purchaseInfo.purchaseType;

    this.purchaseType = UpperCaseText(this.purchaseType);
    this.index = 0;
  }

  componentDidMount() {
    this.setState({ paymentMethodExpanded: this.props.expandAccordian });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expandAccordian !== this.props.expandAccordian) {
      this.setState({
        paymentMethodExpanded: this.props.expandAccordian,
      });
    }
  }

  flipIcon() {
    this.setState({
      paymentMethodExpanded: !this.state.paymentMethodExpanded,
    });
  }

  eSignRequestStatus = () => {
    return this.purchaseType === PURCHASE_TYPES.SIGN_ONLINE
      ? this.props.t("GeneralEMP.statusYes")
      : this.props.t("GeneralEMP.statusNo");
  };

  getValue = (field) => {
    const { financeDataQuote } = this.props.customerReservation;

    return financeDataQuote && financeDataQuote[field];
  };

  status = () => {
    const { purchaseInfo } = this.props.customerReservation;
    return purchaseInfo &&
      purchaseInfo.contractStatus === PURCHASE_INFO_CONTRACT_STATUS.SIGNED
      ? this.props.t("PurchaseDetails.creditApplication.creditStatus")
      : "--";
  };

  additionalLending = () => {
    return this.props.customerReservation &&
      this.props.customerReservation.alternativeLenderSelection
      ? this.props.customerReservation.alternativeLenderSelection
      : "-";
  };

  getDownPayment = () => {
    let downPayment = this.props.t("PurchaseDetails.paymentMethod.downPayment");
    if (!this.props.isModelEOrder) {
      if (this.props.authorizedBelow) {
        downPayment += this.props.t(
          "PurchaseDetails.paymentMethod.authorizedBelow"
        );
      } else {
        downPayment += this.props.t(
          "PurchaseDetails.paymentMethod.notCollected"
        );
      }
    }
    return downPayment;
  };

  formatMileage = (value) => {
    return NOTNULL_OR_NOTUNDEFINED(value)
      ? Number(parseFloat(value)).toLocaleString("en")
      : "--";
  };

  checkShowDownPayment(purchaseTypeIsSignAtDealer) {
    if (this.props.isModelEOrder) {
      if (
        (this.financeProductType === FINANCE_PRODUCT_TYPE.OTHER &&
          purchaseTypeIsSignAtDealer) ||
        !this.getValue("deposit")
      ) {
        return false;
      } else {
        return true;
      }
    } else if (this.financeProductType === FINANCE_PRODUCT_TYPE.CASH) {
      return false;
    } else {
      return true;
    }
  }

  checkShowEstimatedTotalAmountFinanced(
    purchaseTypeIsSignOnline,
    purchaseTypeIsSignAtDealer
  ) {
    if (this.props.isModelEOrder) {
      if (purchaseTypeIsSignOnline || purchaseTypeIsSignAtDealer) {
        if (
          this.props.financeProductType === FINANCE_PRODUCT_TYPE.FINANCE ||
          this.props.financeProductType === FINANCE_PRODUCT_TYPE.FORD_OPTIONS ||
          this.financeProductType === FINANCE_PRODUCT_TYPE.OTHER
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  render() {
    const { financeType, financingType, paymentDescription, financeStatus } =
      this.props.customerReservation;

    this.index = 0;

    const styles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? fordStyles
        : lincolnStyles;

    const sharedStyles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? fordSharedStyles
        : lincolnSharedStyles;

    const crStyles =
      this.props.user && IS_FORD_BRAND(this.props.user.brand)
        ? crStylesFord
        : crStylesLincoln;

    const { customerReservation } = this.props;

    const paymentMethodTitle =
      this.financeProductType === FINANCE_PRODUCT_TYPE.FINANCE
        ? this.props.t("PurchaseDetails.paymentMethod.finance")
        : this.financeProductType === FINANCE_PRODUCT_TYPE.FORD_OPTIONS
        ? this.props.t("PurchaseDetails.paymentMethod.fordOptionsPlan")
        : this.financeProductType === FINANCE_PRODUCT_TYPE.CASH
        ? this.props.t("PurchaseDetails.paymentMethod.cash")
        : this.financeProductType === FINANCE_PRODUCT_TYPE.LEASE
        ? this.props.t("PurchaseDetails.paymentMethod.lease")
        : this.financeProductType === FINANCE_PRODUCT_TYPE.FLEX_BUY
        ? this.props.t("PurchaseDetails.paymentMethod.flexBuy")
        : this.financeProductType === FINANCE_PRODUCT_TYPE.OTHER
        ? this.props.t("PurchaseDetails.paymentMethod.otherFinanceOptions")
        : this.props.customerReservation.financeProvider ===
          FINANCE_PROVIDER.THIRD_PARTY
        ? this.props.t("PurchaseDetails.purchaseTypes.THIRD_PARTY_FINANCING")
        : null;

    let displayCreditApp =
      this.props.customerReservation.subJourneyType !== PIPELINE &&
      financingType !== THIRD_PARTY_FINANCING &&
      (this.financeProductType === FINANCE_PRODUCT_TYPE.FINANCE ||
        this.financeProductType === FINANCE_PRODUCT_TYPE.LEASE ||
        this.financeProductType === FINANCE_PRODUCT_TYPE.FORD_OPTIONS ||
        this.financeProductType === FINANCE_PRODUCT_TYPE.FLEX_BUY);

    const displayAlternativeLending =
      this.financeProductType === FINANCE_PRODUCT_TYPE.FINANCE;

    if (displayCreditApp) {
      displayCreditApp =
        (this.purchaseType &&
          (this.purchaseType === PURCHASE_TYPES.SIGN_AT_DEALER ||
            this.purchaseType === PURCHASE_TYPES.DISCUSS_WITH_DEALER ||
            this.purchaseType === PURCHASE_TYPES.SIGN_ONLINE ||
            this.purchaseType === PURCHASE_TYPES.ABANDONED_CHECKOUT)) ||
        applicationStatus(paymentDescription) === PENDING_CREDIT_APP ||
        applicationStatus(paymentDescription) === CONDITIONED_CREDIT_APP ||
        applicationStatus(paymentDescription) === DENIED_CREDIT_APP ||
        applicationStatus(paymentDescription) === DENIED_CREDIT_APP_ERROR ||
        (applicationStatus(paymentDescription) === APPROVED &&
          !this.purchaseType);
    }

    const display3PFCreditApp =
      this.props.customerReservation.financeProvider ===
      FINANCE_PROVIDER.THIRD_PARTY;

    const orderDeposit =
      customerReservation &&
      customerReservation.orderPaymentInfo &&
      customerReservation.orderPaymentInfo.depositAmount;

    const orderDepositRefund =
      customerReservation &&
      customerReservation.orderPaymentInfo &&
      customerReservation.orderPaymentInfo.refundAmount;

    const reservationDeposit =
      customerReservation &&
      customerReservation.reservationPaymentInfo &&
      customerReservation.reservationPaymentInfo.depositAmount;

    const reservationDepositRefund =
      customerReservation &&
      customerReservation.reservationPaymentInfo &&
      customerReservation.reservationPaymentInfo.refundAmount;

    const holdDeposit =
      customerReservation &&
      customerReservation.vehicleHoldDepositInfo &&
      customerReservation.vehicleHoldDepositInfo.depositAmount;

    const paymentWithTaxes =
      this.props.pricingStack && this.props.pricingStack.paymentWithTaxes;

    const monthlyPaymentWithTaxes =
      paymentWithTaxes &&
      this.props.pricingStack &&
      this.props.pricingStack.monthlyPayment;

    const monthlyPayment = paymentWithTaxes
      ? monthlyPaymentWithTaxes
      : this.getValue("monthlyPayment");

    const purchaseTypeIsSignAtDealer =
      this.props.customerReservation &&
      this.props.customerReservation.purchaseInfo &&
      this.props.customerReservation.purchaseInfo.purchaseType ===
        PURCHASE_TYPES.SIGN_AT_DEALER;

    const showDownPayment = this.checkShowDownPayment(
      purchaseTypeIsSignAtDealer
    );

    const purchaseTypeIsValid =
      this.props.customerReservation &&
      this.props.customerReservation.purchaseInfo &&
      this.props.customerReservation.purchaseInfo.purchaseType;

    const purchaseTypeIsSignOnline =
      purchaseTypeIsValid &&
      this.props.customerReservation.purchaseInfo.purchaseType ===
        PURCHASE_TYPES.SIGN_ONLINE;

    const showEstimatedTotalAmountFinanced =
      this.checkShowEstimatedTotalAmountFinanced(
        purchaseTypeIsSignOnline,
        purchaseTypeIsSignAtDealer
      );

    return (
      <div>
        <StyledExpansionPanel
          onChange={() => this.flipIcon()}
          expanded={this.state.paymentMethodExpanded}
          data-testid="paymentMethodDropDown"
        >
          <StyledExpansionPanelSummary
            style={crStyles.headerBarBg}
            data-testid="paymentMethod"
          >
            <div className={sharedStyles.headerBar}>
              <div className={sharedStyles.header}>
                <span>
                  {this.props.t("PurchaseDetails.paymentMethod.title")}
                </span>
                {paymentMethodTitle && (
                  <span data-testid="paymentMethodTitle">{` - ${paymentMethodTitle}`}</span>
                )}
              </div>
              <div>
                {this.state.paymentMethodExpanded ? (
                  <ExpandLessIcon data-testid="lessPaymentMethod" />
                ) : (
                  <ExpandMoreIcon data-testid="morePaymentMethod" />
                )}
              </div>
            </div>
          </StyledExpansionPanelSummary>
          {this.financeProductType === FINANCE_PRODUCT_TYPE.LEASE ? (
            <LeasePaymentMethod {...this.props} />
          ) : this.financeProductType === FINANCE_PRODUCT_TYPE.FLEX_BUY ? (
            <FlexBuyPaymentMethod
              {...this.props}
              downPayment={this.getDownPayment()}
            />
          ) : (
            <>
              {(this.financeProductType === FINANCE_PRODUCT_TYPE.FINANCE ||
                this.financeProductType === FINANCE_PRODUCT_TYPE.OTHER ||
                this.financeProductType === FINANCE_PRODUCT_TYPE.CASH ||
                this.financeProductType ===
                  FINANCE_PRODUCT_TYPE.FORD_OPTIONS) && (
                <StyledTableRowWithToolTip
                  isLincolnBrand={
                    this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                  }
                  text={this.props.t("PurchaseDetails.paymentMethod.type")}
                  data={
                    financeType
                      ? this.props.t(
                          `PurchaseDetails.paymentMethod.${financeType}`
                        )
                      : "--"
                  }
                  index={++this.index}
                  testID="type"
                  toolTip={
                    this.financeProductType === FINANCE_PRODUCT_TYPE.FINANCE ? (
                      <SharedToolTip
                        {...this.props}
                        openTestid="paymentMethodTypeTooltipOpen"
                        body={this.props.t(
                          "OrderDetailsConsumerDeposit.paymentMethodTypeTooltipBody"
                        )}
                      />
                    ) : null
                  }
                />
              )}
              {this.financeProductType !== FINANCE_PRODUCT_TYPE.CASH && (
                <StyledTableRowWithToolTip
                  text={this.props.t(
                    "PurchaseDetails.paymentMethod.estimatedTotalSalesPrice"
                  )}
                  data={formatPriceValue(this.getValue("totalSalePrice"))}
                  index={++this.index}
                  testID="estimatedTotalSalesPrice"
                  isLincolnBrand={
                    this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                  }
                  toolTip={
                    <SharedToolTip
                      {...this.props}
                      openTestid="openEstimatedTotalSalesPriceToolTip"
                      body={this.props.t(
                        "PurchaseDetails.paymentMethod.estimatedTotalSalesPriceToolTip"
                      )}
                    />
                  }
                />
              )}

              {reservationDeposit && (
                <StyledTableRow
                  isLincolnBrand={
                    this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                  }
                  text={this.props.t(
                    "OrderDetailsPaymentMethod.reservationDeposit"
                  )}
                  data={reservationDepositRefund ? "$0.00" : reservationDeposit}
                  index={++this.index}
                  testID="reservationDeposit"
                />
              )}
              {orderDeposit && (
                <StyledTableRow
                  isLincolnBrand={
                    this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                  }
                  text={this.props.t("OrderDetailsPaymentMethod.orderDeposit")}
                  data={orderDepositRefund ? "$0.00" : orderDeposit}
                  index={++this.index}
                  testID="orderDeposit"
                />
              )}

              {holdDeposit && (
                <StyledTableRow
                  isLincolnBrand={
                    this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                  }
                  text={
                    this.props.customerReservation.journeyType === "Model-E"
                      ? this.props.t(
                          "OrderDetailsPricingSummary.purchaseDeposit"
                        )
                      : this.props.t("OrderDetailsPaymentMethod.holdDeposit")
                  }
                  data={holdDeposit}
                  index={++this.index}
                  testID="holdDeposit"
                />
              )}

              {this.financeProductType === FINANCE_PRODUCT_TYPE.CASH && (
                <StyledTableRow
                  isLincolnBrand={
                    this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                  }
                  text={this.props.t(
                    "PurchaseDetails.paymentMethod.estimatedCashDueAtDealer"
                  )}
                  data={formatPriceValue(
                    this.props.pricingStack &&
                      this.props.pricingStack.totalPriceWithAddOns
                  )}
                  index={++this.index}
                  testID="estimatedCashDueAtDealer"
                />
              )}

              {showDownPayment && (
                <StyledTableRow
                  isLincolnBrand={
                    this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                  }
                  text={this.getDownPayment()}
                  data={formatPriceValue(this.getValue("deposit"))}
                  index={++this.index}
                  testID="downPayment"
                />
              )}

              {this.financeProductType !== FINANCE_PRODUCT_TYPE.CASH && (
                <>
                  {showEstimatedTotalAmountFinanced && (
                    <StyledTableRow
                      isLincolnBrand={
                        this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                      }
                      text={this.props.t(
                        "PurchaseDetails.paymentMethod.estimatedTotalAmountFinanced"
                      )}
                      data={formatPriceValue(
                        this.getValue("amountFinanced") ||
                          this.getValue("totalAmountFinancedStandard")
                      )}
                      index={++this.index}
                      testID="estimatedTotalAmountFinanced"
                    />
                  )}
                  {this.financeProductType !== FINANCE_PRODUCT_TYPE.OTHER && (
                    <StyledTableRow
                      isLincolnBrand={
                        this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                      }
                      text={this.props.t(
                        "PurchaseDetails.paymentMethod.estimatedMonthlyPayment"
                      )}
                      data={
                        NOTNULL_OR_NOTUNDEFINED(monthlyPayment)
                          ? formatPriceValue(monthlyPayment).concat(
                              this.props.t(
                                "PurchaseDetails.paymentMethod.perMonth"
                              )
                            )
                          : "--"
                      }
                      index={++this.index}
                      testID="estimatedMonthlyPayment"
                    />
                  )}
                  <StyledTableRow
                    isLincolnBrand={
                      this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                    }
                    text={this.props.t("PurchaseDetails.paymentMethod.term")}
                    data={
                      NOTNULL_OR_NOTUNDEFINED(this.getValue("term"))
                        ? this.getValue("term").toString()
                        : "--"
                    }
                    index={++this.index}
                    testID="termInMonths"
                  />
                </>
              )}
              {(this.financeProductType === FINANCE_PRODUCT_TYPE.FINANCE ||
                this.financeProductType === FINANCE_PRODUCT_TYPE.OTHER ||
                this.financeProductType ===
                  FINANCE_PRODUCT_TYPE.FORD_OPTIONS) && (
                <StyledTableRow
                  isLincolnBrand={
                    this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                  }
                  text={
                    IS_USA_MARKET &&
                    this.financeProductType === FINANCE_PRODUCT_TYPE.OTHER
                      ? this.props.t("PurchaseDetails.paymentMethod.apr") +
                        " " +
                        this.props.t(
                          "PurchaseDetails.paymentMethod.aprOthersCaptionForUSA"
                        )
                      : this.props.t("PurchaseDetails.paymentMethod.apr")
                  }
                  data={
                    NOTNULL_OR_NOTUNDEFINED(this.getValue("apr"))
                      ? `${this.getValue("apr")}%`
                      : "--"
                  }
                  index={++this.index}
                  testID="apr"
                />
              )}
              {this.financeProductType ===
                FINANCE_PRODUCT_TYPE.FORD_OPTIONS && (
                <>
                  <StyledTableRow
                    isLincolnBrand={
                      this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                    }
                    text={this.props.t(
                      "PurchaseDetails.paymentMethod.annualMiles"
                    )}
                    data={this.formatMileage(this.getValue("annualMileage"))}
                    index={++this.index}
                    testID="annualMiles"
                  />
                  <StyledTableRow
                    isLincolnBrand={
                      this.props.user && !IS_FORD_BRAND(this.props.user.brand)
                    }
                    text={this.props.t(
                      "PurchaseDetails.paymentMethod.balloonPayment"
                    )}
                    data={formatPriceValue(
                      this.getValue(
                        UpperCaseText(financeType) === "ESTIMATE"
                          ? "residualAmount"
                          : "balloonPayment"
                      )
                    )}
                    index={++this.index}
                    testID="balloonPayment"
                  />
                </>
              )}
            </>
          )}
          {displayCreditApp && (
            <Grid
              container
              className={styles.creditApplication}
              data-testid="creditApplication"
            >
              <Grid item xs={10}>
                <div className={styles.creditTitle}>
                  {this.props.t("PurchaseDetails.creditApplication.title")}
                </div>
              </Grid>
              <Grid item xs={10}>
                <div className={styles.creditHeader}>
                  {this.props.t("PurchaseDetails.creditApplication.submit")}
                </div>
                <div className={styles.creditText} data-testid="creditSubmit">
                  {this.props.t("GeneralEMP.statusYes")}
                </div>
              </Grid>
              <Grid item xs={10}>
                <div className={styles.creditHeader}>
                  {this.props.t(
                    "PurchaseDetails.creditApplication.applicationStatus"
                  )}
                </div>
                <div
                  className={styles.creditText}
                  data-testid="applicationStatus"
                >
                  {applicationStatus(paymentDescription) === PENDING_CREDIT_APP
                    ? this.props.t("PurchaseDetails.creditApplication.pending")
                    : applicationStatus(paymentDescription)}
                </div>
              </Grid>
              <Grid item xs={10}>
                <div className={styles.creditHeader}>
                  {this.props.t(
                    "PurchaseDetails.creditApplication.eSignRequest"
                  )}
                </div>
                <div
                  className={styles.creditText}
                  data-testid="crediteSignRequest"
                >
                  {applicationStatus(paymentDescription) === PENDING_CREDIT_APP
                    ? "--"
                    : this.eSignRequestStatus()}
                </div>
              </Grid>
              <Grid item xs={10}>
                <div className={styles.creditHeader}>
                  {this.props.t("PurchaseDetails.creditApplication.status")}
                </div>
                <div className={styles.creditText} data-testid="status">
                  {this.status.call(this)}
                </div>
              </Grid>
              {displayAlternativeLending && (
                <Grid item xs={10}>
                  <div className={styles.creditHeader}>
                    {this.props.t(
                      "PurchaseDetails.creditApplication.additionalLending"
                    )}
                  </div>
                  <div
                    className={styles.creditText}
                    data-testid="alternativeLending"
                  >
                    {this.additionalLending.call(this)}
                  </div>
                </Grid>
              )}
            </Grid>
          )}
          {display3PFCreditApp && (
            <Grid
              container
              className={styles.creditApplication}
              data-testid="thirdPartyCreditApplication"
            >
              <Grid item xs={10}>
                <div className={styles.creditTitle}>
                  {this.props.t(
                    "PurchaseDetails.creditApplication.thirdPartyTitle"
                  )}
                </div>
              </Grid>
              <Grid item xs={10}>
                <div className={styles.creditHeader}>
                  {this.props.t(
                    "PurchaseDetails.creditApplication.applicationStatus"
                  )}
                </div>
                <div
                  className={styles.creditText}
                  data-testid="thirdPartyApplicationStatus"
                >
                  {financeStatus === INITIATED
                    ? "Submitted"
                    : financeStatus === APPROVED
                    ? "Accepted"
                    : "--"}
                </div>
              </Grid>
            </Grid>
          )}
        </StyledExpansionPanel>
      </div>
    );
  }
}

export default withTranslation("emp")(PaymentMethod);
