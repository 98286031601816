import axios from "axios";
import AuthenticationFailureHandler from "../utils/AuthenticationFailureHandler";
import { getHeaders } from "../utils/AuthorizationUtil";
import { RESERVATION_ACTION_URL, RESERVATION_BASE_URL } from "../utils/EmpUtil";
import { is401 } from "../errors/ErrorConstants";

export default class CustomerReservationsClient {
  static baseUrl = RESERVATION_BASE_URL;
  static actionUrl = RESERVATION_ACTION_URL;

  static getCustomerReservations(
    token,
    dealerCommonId,
    currentPage,
    lang,
    sortBy
  ) {
    const url = `${this.baseUrl}/dealer/customer-reservations`;
    dealerCommonId = dealerCommonId.split("%7C").join("|");
    return axios
      .post(
        url,
        {
          commonIds: dealerCommonId,
          currentPage: currentPage,
          sort: sortBy,
          lang: lang,
        },
        getHeaders(token)
      )
      .then((response) => {
        // looking for an order with certain attributes? uncomment and modify this code
        // for (let i = 0; i < response.data.pagination.numberOfPages; i++) {
        //   axios
        //     .post(
        //       url,
        //       {
        //         commonIds: dealerCommonId,
        //         currentPage: i,
        //         sort: sortBy,
        //         lang,
        //       },
        //       getHeaders(token)
        //     )
        //     .then((page) => {
        //       for (let j = 0; j < page.data.reservations.length; j++) {
        //         this.getCustomerOrderDetails(
        //           { token },
        //           page.data.reservations[j].id,
        //           dealerCommonId,
        //           false,
        //           lang
        //         ).then((orderDetail) => {
        //           const reservation = orderDetail.customerReservation;
        //
        //           // conditions go here
        //           if (reservation.paymentType === "Lease") {
        //             console.log(`${reservation.dealerId}\n${reservation.id}`);
        //           }
        //         });
        //       }
        //     });
        // }

        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static getCustomerOrders(token, dealerCommonId, tab, lang, pageNumber, sort) {
    let statusList = [];
    let orderType = null;
    let journeyType = null;
    let purchaseRequestOrders = false;

    if (tab === "orders_requiring_attention") {
      statusList = ["SUBMITTED"];
      orderType = "Order";
    } else if (tab === "reservations_submitted") {
      orderType = "Reservation";
      statusList = ["CREATED", "CANCELLED", "AMENDED"];
    } else if (tab === "orders_submitted") {
      orderType = "Order";
      statusList = [
        "REJECTED",
        "CANCELLED",
        "CONFIRMED",
        "CONTRACTED",
        "CONVERTED",
        "ORDERED",
        "SOLD",
      ];
    } else if (tab === "purchase_request") {
      orderType = "Order";
      purchaseRequestOrders = true;
    } else if (tab === "model_e_orders") {
      journeyType = "Model-E";
    }

    dealerCommonId = dealerCommonId.split("%7C").join("|");
    statusList = statusList.join(",");
    const url = `${this.baseUrl}/dealer/customer-reservations`;

    return axios
      .post(
        url,
        {
          commonIds: dealerCommonId,
          status: statusList,
          currentPage: pageNumber,
          orderType: orderType,
          sort: sort,
          lang: lang,
          purchaseRequestOrders: purchaseRequestOrders,
          journeyType: journeyType,
        },
        getHeaders(token)
      )
      .then((response) => {
        // looking for an order with certain attributes? uncomment and modify this code
        // for (let i = 0; i < response.data.pagination.numberOfPages; i++) {
        //   axios
        //     .post(
        //       url,
        //       {
        //         commonIds: dealerCommonId,
        //         currentPage: i,
        //         sort,
        //         lang,
        //       },
        //       getHeaders(token)
        //     )
        //     .then((page) => {
        //       for (let j = 0; j < page.data.reservations.length; j++) {
        //         this.getCustomerOrderDetails(
        //           { token },
        //           page.data.reservations[j].id,
        //           dealerCommonId,
        //           false,
        //           lang
        //         ).then((orderDetail) => {
        //           const reservation = orderDetail.customerReservation;
        //
        //           // conditions go here
        //           if (reservation.purchaseDeposit) {
        //             console.log(`${reservation.dealerId}\n${reservation.id}`, reservation.purchaseDeposit.acceptRejectStatus);
        //           }
        //         });
        //       }
        //     });
        // }
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }

        return Promise.reject(error);
      });
  }

  static actionOnOrder(token, dealerCommonId, dealerActionInfo, dealerUserId) {
    dealerCommonId = dealerCommonId.split("|").join("%7C");
    const url = `${this.actionUrl}/dealer/${dealerCommonId}/customer-orders?dealerUserId=${dealerUserId}`;

    return axios.post(url, dealerActionInfo, getHeaders(token));
  }

  static updateOrder(token, dealerCommonId, dealerActionInfo, dealerUserId) {
    dealerCommonId = dealerCommonId.split("|").join("%7C");
    const url = `${this.actionUrl}/dealer/${dealerCommonId}/customer-orders?dealerUserId=${dealerUserId}`;

    return axios
      .post(url, dealerActionInfo, getHeaders(token))
      .then(() => {
        return Promise.resolve();
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static saveDealerAction(
    token,
    dealerCommonId,
    dealerActionInfo,
    dealerUserId
  ) {
    dealerCommonId = dealerCommonId.split("|").join("%7C");
    const url = `${this.actionUrl}/dealer/${dealerCommonId}/save-dealer-actions?dealerUserId=${dealerUserId}`;

    return axios
      .post(url, dealerActionInfo, getHeaders(token))
      .then(() => {
        return Promise.resolve();
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static getAppointmentStatus(token, dealerCommonId, orderId) {
    const commonId = dealerCommonId
      ? dealerCommonId.split("|").join("%7C")
      : "";
    const url = `${this.actionUrl}/dealer/${commonId}/get-appointment-status?orderId=${orderId}`;

    return axios
      .get(url, getHeaders(token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static getAppointmentStatusMultiple(token, dealerCommonId, orderIds) {
    const url = `${this.actionUrl}/dealer/${dealerCommonId}/get-appointment-status-multiple`;

    return axios
      .post(url, orderIds, getHeaders(token))
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static updateHandOver(token, handOverData, orderId) {
    const url = `${this.baseUrl}/dealer/customer-reservations/handover-update/${orderId}`;

    return axios
      .post(url, handOverData, getHeaders(token))
      .then(() => {
        return Promise.resolve();
      })
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static getCancelOrderReasons(
    token,
    lang,
    type,
    // purchaseRequest = false,
    // consentReasons = false,
    // reservationReasons = false,
    // vehicleHoldReasons = false,
    commonId = null
  ) {
    let url = `${this.baseUrl}/dealer/cancelOrderReasons?lang=${lang}`;

    if (type === "all") {
      url = url + `&purchaseRequest=false`;
    }

    if (type === "purchaseRequest") {
      url = url + `&purchaseRequest=true`;
    }

    if (type === "consentReasons") {
      url = url + `&consentReasons=true`;
    }

    if (type === "reservationReasons") {
      url = url + `&reservationReasons=true`;
    }
    if (type === "vehicleHoldReasons") {
      url = url + `&vehicleHoldReasons=true`;
    }

    if (commonId) {
      const dealerCommonId = commonId.split("|").join("%7C");
      url = url + `&commonId=${dealerCommonId}`;
    }

    return axios.get(url, getHeaders(token));
  }

  static getInventoryWindowSticker(token, dealerPACode, vin) {
    if (dealerPACode && vin) {
      const url = `${this.baseUrl}/dealer/window-sticker?dealerPACode=${dealerPACode}&vin=${vin}`;
      return axios
        .get(url, getHeaders(token))
        .then((response) => response.data)
        .catch((error) => {
          if (is401(error)) {
            AuthenticationFailureHandler.execute();
          }
          return Promise.reject(error);
        });
    }
  }

  static async fetchOrderDetails(user, orderId, commonId, isCrc, lang, market) {
    let url = `${this.baseUrl}/dealer/customer-reservations/${orderId}?lang=${lang}`;
    url = url + (isCrc ? "&market=" + market : "&dealerId=" + commonId);
    return await axios.get(encodeURI(url), getHeaders(user.token));
  }

  static async getCustomerOrderDetails(
    user,
    orderId,
    commonId,
    isCrc,
    lang,
    market
  ) {
    let url = `${this.baseUrl}/dealer/customer-reservations/${orderId}?lang=${lang}`;
    url = url + (isCrc ? "&market=" + market : "&dealerId=" + commonId);
    return await axios
      .get(encodeURI(url), getHeaders(user.token))
      .then((response) => response.data)
      .catch(async (error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static async getPricingStack(user, orderId, market, commonId, lang) {
    const dealerCommonId = commonId ? commonId.split("%7C").join("|") : "";

    let url = `${
      this.baseUrl
    }/dealer/customer-reservations-pricing/${orderId}?lang=${lang ? "en" : ""}`;
    url = commonId ? url + ("&dealerId=" + dealerCommonId) : url;
    url = market ? url + ("&market=" + market) : url;
    const response = await axios.get(encodeURI(url), getHeaders(user.token));

    return response?.data;
  }

  static async getOrderPricingStack(user, orderId, market, commonId, lang) {
    const dealerCommonId = commonId ? commonId.split("%7C").join("|") : "";

    let url = `${
      this.baseUrl
    }/dealer/customer-reservations-pricing/${orderId}?lang=${lang ? "en" : ""}`;
    url = commonId ? url + ("&dealerId=" + dealerCommonId) : url;
    url = market ? url + ("&market=" + market) : url;
    return await axios
      .get(encodeURI(url), getHeaders(user.token))
      .then((response) => response.data)
      .catch(async (error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static async searchCustomerReservations(
    searchQuery,
    user,
    lang,
    commonIds,
    market,
    lastName = null,
    zipCode = null,
    isLastNameSearch
  ) {
    let url = `${this.baseUrl}/dealer/customer-reservations/search?query=${searchQuery}&lang=${lang}`;

    if (lastName && zipCode) {
      url = `${this.baseUrl}/dealer/customer-reservations/search?lastName=${lastName}&zipCode=${zipCode}&lang=${lang}`;
    } else if (isLastNameSearch) {
      url = `${this.baseUrl}/dealer/customer-reservations/search?query=${searchQuery}&isLastNameSearch=${isLastNameSearch}&lang=${lang}`;
    }

    if (commonIds) {
      url = url + `&commonIds=${commonIds.split("|").join("%7C")}`;
    }

    if (market) {
      url = url + `&market=${market}`;
    }

    return axios
      .get(url, getHeaders(user.token))
      .then((response) => response.data)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static async saveValuation(valuationRequest) {
    const url = `${this.baseUrl}/dealer/customer-reservations/trade-in`;

    return axios
      .put(
        url,
        {
          expiryDate: valuationRequest.expiryDate,
          valuationAmount: valuationRequest.valuationAmount,
          appraisalId: valuationRequest.appraisalId,
          orderId: valuationRequest.orderId,
        },
        getHeaders(valuationRequest.user.token)
      )
      .then((response) => response.data)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }

  static async sendToUVIS(uvisRequest) {
    const url = `${this.baseUrl}/dealer/customer-reservations/uvis`;

    return axios
      .post(url, uvisRequest, getHeaders(uvisRequest.token))
      .then((response) => response.data)
      .catch((error) => {
        if (is401(error)) {
          AuthenticationFailureHandler.execute();
        }
        return Promise.reject(error);
      });
  }
}
