export const TERMS_AND_CONDITIONS = "TERMS_CONDITIONS";

export const PRICING = "PRICING";

export const RESERVATION_DEPOSIT = "RESERVATION_DEPOSIT";

export const STORE_ID = "STORE_ID";

export const ACCESSORIES_PRICING = "ACCESSORIES_PRICING";

export const DELIVERY_BANDS = "DELIVERY_BANDS";

export const VARIABLE_MARKETING = "VM";

export const OPT_IN = "OPT_IN";

export const GPIS_PRICING = "GPIS_PRICING";

export const NEW_VEHICLE_LINE = "NEW_VEHICLE_LINE";

export const ALD_PRICING = "ALD_PRICING";

export const PAYMENT_THRESHOLD = "PAYMENT_THRESHOLD";

export const CUPID_ID = "CUPID_ID";

export const LEAD_TIMES = "LEAD_TIMES";

export const WALLBOX = "WALLBOX";

export const CUSTOMER_CONTRACT = "CUSTOMER_CONTRACT";

export const CA_MACHE_CATALOG_ID = "WANAC-CGW-2021-CX727";

export const US_MACHE_CATALOG_ID = "WANAB-CGW-2021-CX727";

export const CA_BRONCO_CATALOG_ID = "WANAC-TG1-2021-Bronco";

export const US_BRONCO_CATALOG_ID = "WANAB-TG1-2021-Bronco";

export const CA_BRONCO_SPORT_CATALOG_ID = "WANAC-CHD-2021-BroncoSport";

export const US_BRONCO_SPORT_CATALOG_ID = "WANAB-CHD-2021-BroncoSport";

export const CA_F150_CATALOG_ID = "WANAC-TFD-2022-F-150";

export const US_F150_CATALOG_ID = "WANAB-TFD-2022-F-150";

export const US_BRONCO_SPORT_22_CATALOG_ID = "WANAB-CHD-2022-BroncoSport";

export const US_BRONCO_22_CATALOG_ID = "WANAB-TG1-2022-Bronco";

export const US_F150_22_CATALOG_ID = "WANAB-TFD-2022-F-150";

export const US_MACHE_22_CATALOG_ID = "WANAB-CGW-2022-CX727";

export const CA_BRONCO_SPORT_22_CATALOG_ID = "WANAC-CHD-2022-BroncoSport";

export const CA_BRONCO_22_CATALOG_ID = "WANAC-TG1-2022-Bronco";

export const CA_MACHE_22_CATALOG_ID = "WANAC-CGW-2022-CX727";

export const US_MACHE_23_CATALOG_ID = "WANAB-CGW-2023-CX727";

export const CA_MACHE_23_CATALOG_ID = "WANAC-CGW-2023-CX727";

export const CA_F150_22_CATALOG_ID = "WANAC-TFD-2022-F-150";

export const USA_MARKET_CODE = "USA";

export const ENGLISH = "en";

export const LINCOLN_ENGLISH = "lc_USA";

export const FORD_ENGLISH = "fd_USA";

export const USA_ENGLISH = "en_US";

export const CANADA_ENGLISH = "en_CA";

export const CANADA_FRENCH = "fr_CA";

export const PAGE = "page";

export const QUERY = "query";

export const DEALER_COMMON_ID = "dealerid";

export const BRAND = "brand";

export const ACCESSORIES_KEY = "accessories";

export const MODEL_E_KEY = "modele";

export const MODEL_E_ORDER_DETAILS_KEY = "modeleorderdetails";

export const SEARCH = "search";

export const DELIVERY_TOOLS = "deliverytools";

export const DELIVERY_TOOLS_QR = "deliverytoolsqr";

export const SALESCODE_KEY = "sales.code";

export const ADFS_TOKEN = "adfs.token";

export const MARKET = "market";

export const CASH = "cash";

export const FINANCE = "finance";

export const LEASE = "lease";

export const OFFLINE_LEASE = "offline_lease";

export const OFFLINE_FINANCE = "offline_finance";

export const OFFLINEFINANCE = "offline finance";

export const WBDO_CANCELLATION_REASON = "Order Cancelled by system";

export const FINANCE_PRODUCT_TYPE = {
  FINANCE: "finance",
  LEASE: "lease",
  FLEX_BUY: "flex buy",
  FORD_OPTIONS: "ford options",
  CASH: "cash",
  THIRD_PARTY: "finance",
  DEALER_FINANCING_OPTIONS: "Dealer Financing Options",
  BUSINESS_CONTRACT_HIRE: "lease",
  OTHER: "other financing options",
};

export const FINANCE_PRODUCT_TYPE_MAP = {
  THIRD_PARTY_FINANCING: "ThirdPartyFinancingOptions",
};

export const PDS_STATUS_READY = "Ready";
export const PDS_STATUS_ACCEPT = "Accept";
export const PDS_STATUS_REJECT = "Reject";
export const DOC_UPLOAD_STATUS_COMPLETE = "COMPLETED";
export const DOC_UPLOAD_STATUS_READY = "READY";
export const DOC_UPLOAD_STATUS_LOCKED = "LOCKED";

export const THIRD_PARTY_FINANCING = "Third Party Financing";

export const DELIVERY = "delivery";

export const NA_DEFAULT_CURRENCY = "$";

export const CANCELLED = "CANCELLED";

export const CANCELED = "CANCELED";

export const ACCEPTED = "ACCEPTED";

export const SUBMITTED = "SUBMITTED";

export const FINANCED = "FINANCED";

export const LEASED = "LEASED";

export const OWNED = "OWNED";

export const ACCEPT_AND_SUBMIT = "acceptAndSubmit";
export const ACCEPT_AND_SUBMIT_DROP_OFF_CONFIRM =
  "acceptAndSubmitDropOffConfirm";
export const ACCEPT_AND_SUBMIT_PICK_UP_CONFIRM = "acceptAndSubmitPickUpConfirm";
export const ACCEPT_AND_KEEP = "acceptAndKeep";
export const ACCEPT_AND_KEEP_CONFIRM = "acceptAndKeepConfirm";
export const REJECT = "reject";
export const REJECT_CONFIRM = "rejectConfirm";
export const DECISION = "decision";
export const MINORMAX_REDEMPTION_MILEAGE = 250;

export const INCLUDED = "included";
export const SELECTED = "selected";

export const LANGUAGE_MARKET_MAP = {
  GBR: "en_GB",
  FRA: "fr",
  NOR: "no",
  NLD: "nl",
  CHE: "de_CH",
  DEU: "de",
  ESP: "es_ES",
  ITA: "it_IT",
  AUT: "de_AT",
  BEL: "fr_BE",
  FIN: "fi_FI",
  DNK: "da_DK",
  USA: "en_US",
  CAN: "en_CA",
};

export const INCLUDED_FEATURE = "I";

export const DEFAULT_FEATURE = "D";

export const SELECTED_FEATURE = "S";

export const CHECURRENCY = "CHF";

export const DEALER_ACTIONS = {
  PURCHASE_REQUEST_ACCEPTED: "purchaserequestaccepted",
  PURCHASE_REQUEST_CANCELLED: "purchaserequestcancelled",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
  INSTORE: "INSTORE",
  NA_CANCEL: "na_cancel",
  PURCHASE_DEPOSIT_ACCEPTED: "purchasedepositaccepted",
  PURCHASE_DEPOSIT_REJECTED: "purchasedepositrejected",
  PURCHASE_DEPOSIT_REFUND: "purchasedepositrefund",
  OFFLINE_PURCHASE_DEPOSIT_REFUND: "purchasedepositofflinerefund",
  OFFLINE_ORDER_DEPOSIT_REFUND: "orderdepositofflinerefund",
  OFFLINE_RESERVATION_DEPOSIT_REFUND: "reservationdepositofflinerefund",
  ORDER_DEPOSIT_REFUND: "orderdepositrefund",
  RESERVATION_DEPOSIT_REFUND: "reservationdepositrefund",
  VEHICLE_HOLD_RELEASE: "vehicleholdrelease",
  TRADE_IN_ACCEPT_KEEP: "tradeinacceptandkeep",
  TRADE_IN_ACCEPT_AUCTION: "tradeinacceptandauction",
  TRADE_IN_REJECTED: "tradeinrejected",
  APPOINTMENT_FULFILLED: "appointfulfilled",
  APPOINTMENT_CANCELLED: "appointmentcancelled",
};

export const PURCHASE_DEPOSIT_STATUS = {
  ACCEPTED: "purchasedepositaccepted",
  DECLINED: "purchasedepositacceptdeclined",
  FAILED: "purchasedepositacceptfailed",
  REJECTED: "purchasedepositrejected",
  REFUNDED: "purchasedepositrefund",
  CREATED: "purchasedepositcreated",
  ENABLE: "enable",
};

export const VEHICLE_HOLD_DEPOSIT_STATUS = {
  ACCEPTED: "vehicleholddepositaccepted",
  DECLINED: "vehicleholddepositacceptdeclined",
  FAILED: "vehicleholddepositacceptfailed",
  REJECTED: "vehicleholddepositrejected",
  REFUNDED: "vehicleholddepositrefund",
};

export const PURCHASE_REFUND_STATUS = {
  ACCEPTED: "purchasedepositrefund",
  DECLINED: "purchasedepositrefunddeclined",
  FAILED: "purchasedepositrefundfailed",
  REFUNDED_OFFLINE: "purchasedepositofflinerefund",
};

export const VEHICLE_HOLD_REFUND_STATUS = {
  ACCEPTED: "vehicleholddepositrefund",
  DECLINED: "vehicleholddepositrefunddeclined",
  FAILED: "vehicleholddepositrefundfailed",
  REFUNDED_OFFLINE: "vehicleholddepositofflinerefund",
};

export const ORDER_DEPOSIT_STATUS = {
  REFUNDED: "orderdepositrefund",
  FAILED: "orderdepositrefundfailed",
  DENIED: "orderdepositrefunddenied",
  DECLINED: "orderdepositrefunddeclined",
  REFUNDED_OFFLINE: "orderdepositofflinerefund",
};

export const RESERVATION_DEPOSIT_STATUS = {
  REFUNDED: "reservationdepositrefund",
  FAILED: "reservationdepositrefundfailed",
  DENIED: "reservationdepositrefunddenied",
  DECLINED: "reservationdepositrefunddeclined",
  REFUNDED_OFFLINE: "reservationdepositofflinerefund",
};

export const REFUND_TYPE = {
  PURCHASE_DEPOSIT: "PurchaseDeposit",
  RESERVATION_DEPOSIT: "ReservationDeposit",
  ORDER_DEPOSIT: "OrderDeposit",
  HOLD_DEPOSIT: "HoldDeposit",
};

export const PURCHASE_TYPES = {
  CASH: "CASH",
  CONTINUE_AT_DEALER: "CONTINUE_AT_DEALER",
  CREDIT_APPLICATION: "CREDIT_APPLICATION",
  DISCUSS_WITH_DEALER: "DISCUSS_WITH_DEALER",
  SIGN_AT_DEALER: "SIGN_AT_DEALER",
  SIGN_OFFLINE: "SIGN_OFFLINE",
  SIGN_ONLINE: "SIGN_ONLINE",
  THIRD_PARTY: "THIRD_PARTY",
  DEALER_FINANCING: "DEALER_FINANCING",
  DENIED_CREDIT_APPLICATION: "DENIED_CREDIT_APPLICATION",
  PENDING_CREDIT_APPLICATION: "PENDING_CREDIT_APPLICATION",
  ABANDONED_CHECKOUT: "ABANDONED_CHECKOUT",
};

export const FINANCE_PROVIDER = {
  THIRD_PARTY: "AutoFi",
};

export const PENDING_CREDIT_APP = "CUSTOMER_TIMER_EXPIRED";
export const CONDITIONED_CREDIT_APP = "CONDITIONED";
export const DENIED_CREDIT_APP = "DENIED";
export const APPROVED = "APPROVED";
export const DENIED_CREDIT_APP_ERROR = "ERROR";
export const INITIATED = "INITIATED";

export const PURCHASE_REQUEST_STATUS = {
  CREATED: "CREATED",
  SOLD: "SOLD",
  ACCEPTED: "ACCEPTED",
  REJECTED: "REJECTED",
  PENDING: "PENDING",
  CANCELED: "CANCELED",
};

export const COMPLETED = "COMPLETED";

export const PURCHASE_REQUEST_BUSINESS_STATUS = {
  ACCEPTED: "PURCHASEREQUESTACCEPTED",
  CANCELLED: "PURCHASEREQUESTCANCELLED",
};

export const AUCTION_SITE_MAP = {
  C10: "MD-ELKRIDGE-MANHEIM BALTIMORE/WASHINGTON-C10",
  C11: "IN-PLAINFIELD-ADESA INDIANAPOLIS AUCTION-C11",
  C16: "CA-RIO LINDA-ADESA BRASHERS (SACRAMENTO)-C16",
  C18: "MN-MAPLE GROVE-MANHEIM MINNEAPOLIS-C18",
  C19: "IL-MATTESON-MANHEIM CHICAGO-C19",
  C20: "NY-NEWBURGH-MANHEIM NEW YORK-C20",
  C22: "MS-HATTIESBURG-MANHEIM MISSISSIPPI-C22",
  C23: "NC-GREENSBORO-GREENSBORO AUTO AUCTION-C23",
  C35: "TX-FORT WORTH-MANHEIM DALLAS-FT. WORTH-C35",
  C38: "OH-GROVE CITY-MANHEIM OHIO-C38",
  C40: "TX-SAN ANTONIO-MANHEIM SAN ANTONIO-C40",
  C41: "PA-MANHEIM-MANHEIM AUTO AUCTION-C41",
  C42: "FL-TAMPA-MANHEIM TAMPA-C42",
  C49: "GA-ATLANTA-MANHEIM GEORGIA-C49",
  C63: "NJ-BORDENTOWN-MANHEIM NEW JERSEY-C63",
  C69: "PA-CRANBERRY TOWNSHIP-MANHEIM PITTSBURGH-C69",
  C75: "HI-HONOLULU-MANHEIM HAWAII-C75",
  C98: "AK-ANCHORAGE-DEALERS AUTO AUCTION OF ALASKA-C98",
  F79: "TN-MT. JULIET-MANHEIM NASHVILLE-F79",
  F80: "MI-CARLETON-MANHEIM DETROIT-F80",
  F81: "MA-FRAMINGHAM-ADESA BOSTON-F81",
  F82: "CO-AURORA-MANHEIM DENVER-F82",
  F84: "MO-KANSAS CITY-MANHEIM KANSAS CITY-F84",
  F87: "CA-FONTANA-MANHEIM SOUTHERN CAL-F87",
  F90: "UT-SALT LAKE CITY-ADESA SALT LAKE-F90",
  F91: "WA-SPOKANE-DAA NORTHWEST-F91",
  F92: "AZ-CHANDLER-ADESA PHOENIX  AA-F92",
  F99: "WA-AUBURN-DAA SEATTLE-F99",
};

export const STATE_LIST = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

// Progress Tracker
// 0: Order Placed, 1: In Production, 2: Shipping, 3: Delivery
export const ORDER_STATUS = {
  1: ["strt", "vdos", "vshp", "prod", "iprl", "grta", "vasc", "vitr"],
  2: ["vprt", "vhlg", "vetl", "vmod", "vcrb", "vand", "vcmp", "vehs", "vafd"],
  3: ["vphd"],
};
//0: Pre-Order Placed, 1: Order Placed, 2: In Production, 3: On Its Way, 4: Delivered
export const RESERVATION_STATUS = {
  2: ["strt", "vdos", "vshp", "prod", "iprl", "grta", "vasc", "vitr"],
  3: ["vprt", "vhlg", "vetl", "vmod", "vcrb", "vand", "vcmp", "vehs", "vafd"],
  4: ["vphd"],
};

export const DECIMAL_PLACES = 2;

export const CUSTOMER_HANDLING_ROUTES = [
  "orders_requiring_attention",
  "reservations_submitted",
  "orders_submitted",
  "purchase_request",
  "model_e_orders",
];
export const LINCOLN_CUSTOMER_HANDLING_ROUTES = ["purchase_request"];
export const REGEX_LIST = {
  number: /^\d*\.?(\.\d{1,2})?$/,
  phone: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  email:
    /^(?=.{4,40}$)((([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  zip: /^\d{0,9}$/,
  vinBeingPurchased: /^[^iIoOqQ'-]{10,17}$/,
};
export const DEALER_SETUP =
  "https://ecommdealersetup.dealerconnection.com/vue/dashboard";
export const CHECK_RECALLS =
  "https://www.fordtechservice.dealerconnection.com/";
export const CHECK_RECALLS_WITH_VIN =
  "https://www.fordtechservice.dealerconnection.com/transport/?link=500&param=";
export const RESOURCE_CENTER =
  "https://ecommdealersetup.dealerconnection.com/vue/tools";

export const PURCHASE_INFO_CONTRACT_STATUS = {
  SIGNED: "CONTRACT_SIGNED",
};
export const MARKET_LANGUAGES = {
  BEL: ["nl", "fr"],
  CHE: ["fr", "de"],
  CAN: ["en", "fr"],
};
export const LANCODES = {
  en: "ENGLISH",
  fr: "FRENCH",
  de: "GERMAN",
  nl: "DUTCH",
  no: "NORWEGIAN",
  es: "SPANISH",
  it: "ITALIAN",
  fi: "FINNISH",
  da: "DANISH",
};

// Regions for translation tool
export const TRANSLATION_TOOL_REGIONS = {
  EU: [
    "GBR",
    "DNK",
    "AUT",
    "DEU",
    "CHE",
    "ESP",
    "FIN",
    "BEL",
    "FRA",
    "ITA",
    "NLD",
    "NOR",
  ],
  CAN: ["CAN"],
};

export const BRAND_NAMES = {
  FORD: "FORD",
  LINCOLN: "LINCOLN",
};

export const VEHICLE_HOLD_ICON = "vehicleHoldIcon";
export const HOLD_OR_DOWN_PAYMENT_ACTION_ICON = "holdOrDownPaymentActionIcon";
export const TRADE_IN_ACTION_ICON = "tradeInActionIcon";
export const PURCHASE_REQUEST_ACCEPT_ICON = "purchaseRequestAcceptIcon";
export const ACTION_REQUIRED_ICON = "actionRequiredIcon";

export const ORDER = "order";
export const RESERVATION = "reservation";
export const PURCHASE_REQUEST = "purchaseRequest";

export const ORDER_CHANNEL_INSTORE = "INSTORE";

export const possibleQualifiedIncentiveTypes = [
  "Affinity - College Student",
  "Affinity - First Responder",
  "Affinity - Farm Bureau",
  "Affinity - Military",
];

export const PREPROD_SCHEDULER_URL = "https://deliveryday-dev.ford.com/";
export const QA_SCHEDULER_URL = "https://deliveryday-qa.ford.com/";
export const PROD_SCHEDULER_URL = "https://deliveryday.ford.com/";

export const PREPROD_ANALYTICS_URL =
  "https://assets.adobedtm.com/248d63c2ff6b/af15eb075ef3/launch-1432fdba9af4-staging.min.js";
export const PROD_ANALYTICS_URL =
  "https://assets.adobedtm.com/248d63c2ff6b/af15eb075ef3/launch-608cd9ccb1b2.min.js";

export const PREPROD_DDT_DOWNLOAD_URL =
  "itms-services://?action=download-manifest&url=https://tistatic.ford.com/manifest-qa.plist";
export const PROD_DDT_DOWNLOAD_URL =
  "itms-services://?action=download-manifest&url=https://tistatic.ford.com/manifest.plist";

export const PREPROD_DYNATRACE_URL =
  "https://cdn.dynatrace.ford.com/qfordcom/1af78709efe85d4d_complete.js";
export const PROD_DYNATRACE_URL =
  "https://www.ford.com/6bb0a6072175257e_complete.js";

export function generateSmartVincentLink(vin) {
  const baseUrl =
    "https://www.smartvincent.dealerconnection.com/smartvincent/svaccess.do";
  const queryParams = `?vin=${vin}&source=vehiclelocator&Language=E`;
  return vin ? `${baseUrl}${queryParams}` : baseUrl;
}

export const MODELE_DEALER_INTERACTIONS = {
  GUIDED_DRIVE: "Guided drive",
  NEW_OWNER: "New owner overview",
  JUST_KEYS: "Just hand me the keys",
};

export const PIPELINE = "PIPELINE";
export const NON_PIPELINE = "NON_PIPELINE";
export const MODEL_E = "Model-E";

export const ORDER_VIN_UNAVAILABLE = "ORDER_VIN_UNAVAILABLE";

export const AXZ_PIN_VALID = "Valid";

export const VENDOR_CATEGORY_RRC = "RRC";
export const VENDOR_CATEGORY_DEALER = "DEALER";
