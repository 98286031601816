import { withTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomerReservationsClient from "../../../clients/CustomerReservationsClient";
import SubNav from "../../../shared/subnav/SubNav";
import SearchResults from "../SearchResults";
import { IS_FORD_BRAND } from "../../../utils/EmpUtil";
import fordStyles from "../NACustomerOrders.module.scss";
import lincolnStyles from "../NACustomerOrdersLincoln.module.scss";
import DealerDeliveryToolClient from "../../../clients/DealerDeliveryToolClient";
import { logMsg } from "../../../common/Logger";
import {
  ERROR_PROCESSING,
  isProcessingError,
} from "../../../errors/ErrorConstants";
import Skeleton from "@material-ui/lab/Skeleton";

const SearchResultsWrapper = (props) => {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [appointments, setAppointments] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const { keyword } = useParams();

  useEffect(() => {
    setSearchKeyword(keyword);
    searchOrder(keyword);
  }, [keyword]);

  const getAppointments = async () => {
    await DealerDeliveryToolClient.getAppointments(
      props.token,
      props.user.dealerId
    )
      .then((response) => {
        response !== null && setAppointments(response.appointmentsByOrderId);
      })
      .catch(() => {
        /*this.setState({ hasError: [LOAD_ERROR] });*/
        logMsg("Error fetching appointments.");
      });
  };

  const searchOrder = (keyword) => {
    setLoading(true);
    let orders = [];

    let dealers = "";
    if (props.user.market === "CAN" && props.user.showAll) {
      dealers = props.user.getCommonIds();
    } else {
      dealers = props.user.commonDealerId;
    }

    const checkLastName = (keyword) => {
      const trimmedKeyword = keyword.trim();
      return (
        !trimmedKeyword.includes(" ") &&
        !trimmedKeyword.includes("@") &&
        !/\d/.test(trimmedKeyword)
      );
    };

    const isLastNameSearch = checkLastName(keyword);

    CustomerReservationsClient.searchCustomerReservations(
      keyword.trim(),
      { token: props.token },
      props.user.lang,
      dealers,
      null,
      null,
      null,
      isLastNameSearch
    )
      .then((response) => {
        orders = response;
        if (orders !== null) {
          setOrders(orders);
          let getAppointmentsForSearch = false;
          orders.forEach((order) => {
            if (order.journeyType === "Model-E") {
              getAppointmentsForSearch = true;
            }
          });
          if (getAppointmentsForSearch) getAppointments();
        }
      })
      .catch(() => {
        orders = [];
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getNoContent = (tab) => {
    const styles = IS_FORD_BRAND(props.user.brand) ? fordStyles : lincolnStyles;

    return (
      <div className={styles.noResultsDiv}>
        <div className={styles.noResultsFound}>
          {props.t("CustomerOrders." + tab)}
        </div>
      </div>
    );
  };

  const updateOrder = async (
    orderId,
    selectedTab,
    tab,
    dealerCommonId,
    rejectReason
  ) => {
    const naDealerActionInfo = {
      reservationId: orderId,
      dealerAction: selectedTab,
    };

    if (rejectReason) naDealerActionInfo.dealerRejectedReason = rejectReason;

    props.hideOrShow(true);

    try {
      await CustomerReservationsClient.updateOrder(
        props.token,
        dealerCommonId,
        naDealerActionInfo,
        props.user.userId
      );
      searchOrder(searchKeyword);
    } catch (err) {
      if (isProcessingError(err)) {
        this.setState({ hasError: [ERROR_PROCESSING] });
      }
    } finally {
      props.hideOrShow(false);
      searchOrder(searchKeyword);
    }
  };

  const styles = IS_FORD_BRAND(props.user.brand) ? fordStyles : lincolnStyles;

  return (
    <div id="dashboard" className="pageWrapper">
      <SubNav
        id="subNav"
        dashboard={true}
        title={props.t("GlobalNavBar.dashboard")}
        {...props}
        getNoContent={getNoContent}
        searchKeyword={searchKeyword}
        isDealer={true}
      />
      <div className={styles.resultsWrapper}>
        <div className={`pagePaddingLeft ${styles.searchResultsHeader}`}>
          {props.t("CustomerOrders.searchResults")}
        </div>
        {loading ? (
          <Skeleton variant="rect" animation="wave" height={100} />
        ) : (
          <SearchResults
            {...props}
            orders={orders}
            getNoContent={getNoContent}
            hidePagination={true}
            appointments={appointments}
            updateOrder={updateOrder}
          />
        )}
      </div>
    </div>
  );
};

export default withTranslation("emp")(SearchResultsWrapper);
