import React, { Component } from "react";
import SubNav from "../../../shared/subnav/SubNav";
import { withTranslation } from "react-i18next";
import HasPermissionTo from "../../../common/HasPermissionTo";
import styles from "./DashboardStyles.module.scss";
import DealerReporting from "./DealerReporting/DealerReporting";
import PendingActions from "../pendingActions/PendingActions";
import {
  IS_CANADA_MARKET,
  IS_FORD_BRAND,
  IS_NA_MARKET,
  IS_USA_MARKET,
  ENV,
  IS_FIELD_VIEW_USER,
  IS_CRC_USER,
} from "../../../utils/EmpUtil";
import { displayError, LOAD_ERROR } from "../../../errors/ErrorConstants";
import MainDashboard from "../dashboardCards/DashboardCards";
import InactiveNotification from "../inactiveDealerNotification/InactiveDealerNotification";
import DealerNotificationsClient from "../../../clients/DealerNotificationsClient";
import AccessoriesSearchClient from "../../../clients/AccessoriesSearchClient";
import NADealerNotifications from "../../customerHandling/notifications/NADealerNotifications";
import * as AnalyticsService from "../../../shared/services/analytics/AnalyticsService";
import EMPAnnouncementBanner from "../../../shared/components/EMPAnnouncementBanner/EMPAnnouncementBanner";
import { Box, Paper, Typography } from "@material-ui/core";
import { initUserzoom } from "../../../shared/services/userzoom/UserzoomService";
import EMPDialog from "../../../shared/components/EMPDialog/EMPDialog";
import SplashScreen from "../../splashScreen/SplashScreen";

class DealerDashboard extends Component {
  constructor(props) {
    super(props);
    this.dateRange = 7;
    this.state = {
      selectedTab: 0,
      renderApp: false,
      hasError: [],
      accesoriesPricingNotSet: false,
      dashboardNotifications: {},
      welcomeModalOpen: false,
    };
  }

  componentDidMount() {
    // userzoom test
    this.selectiveUserZoomInit(
      ENV,
      this.props.user.market,
      this.props.user.userType
    );

    AnalyticsService.trackPageLoadEvent("fv:emp:dashboard", this.props.user);

    try {
      this.props.user.dashboardPage
        ? this.setState({ selectedTab: this.props.user.dashboardPage })
        : this.updateDashboardTab(this.state.selectedTab);
      this.setState({ renderApp: true });
    } catch (e) {
      this.setState({ hasError: [LOAD_ERROR] });
    }
    this.getDealerDashboardNotifications();
  }

  handleTabChange = (event, selectedTab) => {
    this.updateDashboardTab(selectedTab);
    this.setState({ selectedTab });
  };

  trackBannerClick = () => {
    AnalyticsService.trackOnClickEvent(
      "fv:emp:dashboard",
      "emp:dashboard:content:learn about ford power promise",
      "fv:emp:dashboard:content action",
      this.props.user
    );
  };

  selectiveUserZoomInit = (env, market, userType) => {
    if (
      env !== "local" &&
      IS_USA_MARKET(market) &&
      !IS_FIELD_VIEW_USER(userType) &&
      !IS_CRC_USER(userType)
    ) {
      setTimeout(() => initUserzoom(), 2000);
    }
  };

  getDealerDashboardNotifications = () => {
    DealerNotificationsClient.getDealerDashboardNotifications(this.props.user)
      .then((data) => {
        this.setState({
          dashboardNotifications: {
            ...data,
          },
        });
      })
      .catch(() => {
        this.hasError = true;
      })
      .finally(async () => {
        if (
          this.props.userDetails &&
          (this.props.userDetails.roles.includes("EMP_ECOMM_DEALER") ||
            this.props.userDetails.roles.includes("EMP_ECOMM_ACCESSORIES") ||
            this.props.userDetails.roles.includes("FIELD_VIEW") ||
            this.props.userDetails.roles.includes("EMP_DLR_PRNCPL"))
        ) {
          AccessoriesSearchClient.searchAccessoriesByDateRange(
            this.props.user,
            this.dateRange
          )
            .then((data) => {
              const dashboardNotifications = this.state.dashboardNotifications;
              dashboardNotifications.accessoriesData =
                this.parseAccessoriesData(data);
              this.setState({
                dashboardNotifications: dashboardNotifications,
                accesoriesPricingNotSet: false,
              });
            })
            .catch(() => {
              this.hasError = true;
              this.setState({ accesoriesPricingNotSet: true });
            })
            .finally(() => {
              this.props.hideOrShow(false);
            });
        } else {
          this.props.hideOrShow(false);
        }
      });
  };

  parseAccessoriesData(accessoriesData) {
    const {
      Bed,
      Electronics,
      Exterior,
      Interior,
      Wheels,
      "": Empty,
      "N/A": NotAvailable,
    } = accessoriesData;
    const bedProducts = Bed ? Bed.length : 0;
    const electronics = Electronics ? Electronics.length : 0;
    const exterior = Exterior ? Exterior.length : 0;
    const interior = Interior ? Interior.length : 0;
    const wheels = Wheels ? Wheels.length : 0;
    const empty = Empty ? Empty.length : 0;
    const notAvailable = NotAvailable ? NotAvailable.length : 0;
    const misc = empty + notAvailable;
    const total =
      bedProducts + electronics + exterior + interior + wheels + misc;

    return {
      bedProducts,
      electronics,
      exterior,
      interior,
      wheels,
      misc,
      total,
    };
  }

  updateDashboardTab = (selectedTab) => {
    this.props.user.updateDashboardTab &&
      this.props.user.updateDashboardTab(selectedTab);
  };

  getTabs() {
    const tabs = [];

    !IS_NA_MARKET(this.props.user.market) &&
      tabs.push({
        menuName: this.props.t("ProgressBar.notifications"),
        enabled: true,
        id: "dashboard",
      });
    this.props.user.userType === "dealer" &&
      tabs.push({
        menuName: this.props.t("ProgressBar.reporting"),
        enabled: true,
        id: "reporting",
      });
    return tabs;
  }

  render() {
    const accessoriesNotifications = [
      {
        notificationType: this.props.t("DealerDashboard.actionRequired"),
        params: {
          NotificationMsg: this.props.t("DealerDashboard.accessoriesNotSet"),
        },
      },
    ];
    if (displayError(this.state.hasError)) {
      throw new Error(this.state.hasError[0]);
    }

    return (
      this.state.renderApp && (
        <div id="dashboard" className="pageWrapper">
          <SplashScreen t={this.props.t} user={this.props.user} />
          <HasPermissionTo
            perform={["inactiveDealerNotification:view"]}
            permissions={this.props.user.permissions.rolePermissions}
            render={() => <InactiveNotification {...this.props} />}
          />
          {this.state.accesoriesPricingNotSet && (
            <NADealerNotifications
              {...this.props}
              notifications={accessoriesNotifications}
              isAccessoriesNotification={true}
            />
          )}
          <HasPermissionTo
            perform={["dealerReporting:view"]}
            permissions={this.props.user.permissions.rolePermissions}
            render={() => {
              return (
                <SubNav
                  dashboard={true}
                  selectedTab={this.state.selectedTab}
                  title={this.props.t("GlobalNavBar.dashboard")}
                  action={this.handleTabChange}
                  tabs={this.getTabs()}
                  {...this.props}
                  isDealer={true}
                />
              );
            }}
            noRender={() => {
              return (
                <SubNav
                  id="subNav"
                  dashboard={true}
                  title={this.props.t("GlobalNavBar.dashboard")}
                  {...this.props}
                  isDealer={true}
                />
              );
            }}
          />

          {this.state.selectedTab === 0 ? (
            <HasPermissionTo
              perform={["pendingActionQueue:view"]}
              permissions={this.props.user.permissions.rolePermissions}
              render={() => <PendingActions {...this.props} />}
            />
          ) : (
            <HasPermissionTo
              perform={["dealerReporting:view"]}
              permissions={this.props.user.permissions.rolePermissions}
              condition={this.state.selectedTab === 1}
              render={() => <DealerReporting {...this.props} />}
            />
          )}
          {IS_USA_MARKET(this.props.user.market) &&
            IS_FORD_BRAND(this.props.user.brand) && (
              <Box mt={4} mx={[3, 3, 6]}>
                <EMPAnnouncementBanner
                  text={this.props.t("AnnouncementBanner.announcement")}
                  linkText={this.props.t(
                    "AnnouncementBanner.announcementLinkText"
                  )}
                  textAlign="center"
                  handleClick={() => {
                    this.trackBannerClick();
                    this.setState({ welcomeModalOpen: true });
                  }}
                ></EMPAnnouncementBanner>
              </Box>
            )}
          <HasPermissionTo
            perform={["dashboardCards"]}
            permissions={this.props.user.permissions.rolePermissions}
            condition={
              IS_USA_MARKET(this.props.user.market) ||
              IS_CANADA_MARKET(this.props.user.market)
            }
            render={() => (
              <MainDashboard
                {...this.props}
                accesoriesPricingNotSet={this.state.accesoriesPricingNotSet}
                dashboardNotifications={this.state.dashboardNotifications}
                getDealerDashboardNotifications={() =>
                  this.getDealerDashboardNotifications()
                }
              />
            )}
            noRender={() => {
              return (
                (IS_USA_MARKET(this.props.user.market) ||
                  IS_CANADA_MARKET(this.props.user.market)) && (
                  <Paper className={styles.comingSoonContainer}>
                    <div
                      className={styles.comingSoonTitle}
                      data-testid="comingSoonTitle"
                    >
                      {this.props.t("DealerDashboard.comingSoonTitle")}
                    </div>
                    <div
                      className={styles.comingSoonPrimaryText}
                      data-testid="comingSoonPrimaryText"
                    >
                      {this.props.t("DealerDashboard.comingSoonPrimaryText")}
                    </div>
                    <div
                      className={styles.comingSoonSecondaryText}
                      data-testid="comingSoonSecondaryText"
                    >
                      {this.props.t("DealerDashboard.comingSoonSecondaryText")}
                    </div>
                  </Paper>
                )
              );
            }}
          />
          <EMPDialog
            open={this.state.welcomeModalOpen}
            hasCloseIcon
            handleClose={() => this.setState({ welcomeModalOpen: false })}
            dialogTitle={this.props.t("PromoModal.title")}
            hasActionButton
            actionButtonText="Close"
            onConfirm={() => this.setState({ welcomeModalOpen: false })}
          >
            <Typography
              variant="body1"
              style={{
                fontFamily: "'FordAntennaRegular', sans-serif",
                color: "#000000",
                fontSize: 14,
                lineHeight: "20px",
                marginBottom: "16px",
              }}
            >
              {this.props.t("PromoModal.intro")}
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontFamily: "'FordAntennaRegular', sans-serif",
                color: "#000000",
                fontSize: 14,
                lineHeight: "20px",
                marginBottom: "4px",
              }}
            >
              {this.props.t("PromoModal.item1")}
            </Typography>
            <ul style={{ margin: 0, fontSize: 14, marginBottom: "16px" }}>
              <li>{this.props.t("PromoModal.subitem1")}</li>
            </ul>
            <Typography
              variant="body1"
              style={{
                fontFamily: "'FordAntennaRegular', sans-serif",
                color: "#000000",
                fontSize: 14,
                lineHeight: "20px",
                marginBottom: "4px",
              }}
            >
              {this.props.t("PromoModal.item2")}
            </Typography>
            <ul style={{ margin: 0, fontSize: 14, marginBottom: "16px" }}>
              <li style={{ marginBottom: "8px" }}>
                {this.props.t("PromoModal.subitem2")}
              </li>
              <li style={{ marginBottom: "8px" }}>
                {this.props.t("PromoModal.subitem3")}
              </li>
              <li>{this.props.t("PromoModal.subitem4")}</li>
            </ul>
          </EMPDialog>
        </div>
      )
    );
  }
}

export default withTranslation("emp")(DealerDashboard);
