import React from "react";
import { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import EMPDialog from "../../shared/components/EMPDialog/EMPDialog";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EMPCheckbox from "../../shared/components/EMPCheckbox/EMPCheckbox";
import * as AnalyticsService from "../../shared/services/analytics/AnalyticsService";

const useStyles = makeStyles(() => ({
  subheading: {
    fontFamily: "'FordAntennaRegular', sans-serif",
    fontSize: 16,
    marginBottom: "16px",
  },
  content: {
    fontFamily: "'FordAntennaRegular', sans-serif",
    fontSize: 20,
    marginBottom: "16px",
  },
  image: {
    width: "100%",
    height: "auto",
    border: "1px solid #B2B2B2",
    boxSizing: "border-box",
    display: "block",
    marginTop: "16px",
    marginBottom: "16px",
  },
}));

const SplashScreen = ({ t, user }) => {
  const classes = useStyles();
  const [displayPopUp, setDisplayPopUp] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [hidePopup, setHidePopup] = useState(false);

  const trackUserAction = (action) => {
    AnalyticsService.trackOnClickEvent(
      `fv:emp:dashboard:new order details splash popup`,
      `emp:dashboard:new order details splash popup:${action}`,
      `fv:emp:dashboard:new order details splash popup:content action`,
      user
    );
  };

  useEffect(() => {
    const hidePopup = localStorage.getItem("hideNewFeaturePopup");
    const timer = setTimeout(() => {
      const displayPopUp = !hidePopup;
      setDisplayPopUp(displayPopUp);

      if (displayPopUp) {
        AnalyticsService.trackPageLoadEvent(
          "fv:emp:dashboard:new order details splash popup",
          user
        );
      }
    }, 2000);

    // Cleanup the timeout on component unmount
    return () => clearTimeout(timer);
  }, [user]);

  const title = t("SplashScreen.title");
  const subheading = t("SplashScreen.intro");

  const slides = [
    {
      content: t("SplashScreen.slide1"),
      image: require("./img/001.png"),
      name: "improved interface design",
    },
    {
      content: t("SplashScreen.slide2"),
      image: require("./img/003.png"),
      name: "organization of content by tabs",
    },
    {
      content: t("SplashScreen.slide3"),
      image: require("./img/002.png"),
      name: "large action cards",
    },
    {
      subheading: t("SplashScreen.slide4"),
      content: "",
      image: "",
      name: "stay tuned",
    },
  ];

  const nextSlide = () => {
    const currentSlideName = slides[currentSlide].name;
    trackUserAction(`next:${currentSlideName}`);
    setCurrentSlide((prev) => Math.min(prev + 1, slides.length - 1));
  };

  const prevSlide = () => {
    const currentSlideName = slides[currentSlide].name;
    trackUserAction(`back:${currentSlideName}`);
    setCurrentSlide((prev) => Math.max(prev - 1, 0));
  };

  const closePopUp = () => {
    if (currentSlide === slides.length - 1) {
      const checkboxStatus = hidePopup ? "checked" : "unchecked";
      trackUserAction(`done:${checkboxStatus} do not show this again`);
    }
    if (hidePopup) {
      localStorage.setItem("hideNewFeaturePopup", "true");
    }
    setDisplayPopUp(false);
  };

  const handleClose = () => {
    trackUserAction("close");
    setDisplayPopUp(false);
  };

  const handlePrimaryAction = () => {
    if (currentSlide < slides.length - 1) {
      nextSlide();
    } else {
      closePopUp();
    }
  };

  return (
    <EMPDialog
      open={displayPopUp}
      dialogTitle={title}
      hasCloseIcon={true}
      handleClose={handleClose}
      onConfirm={handlePrimaryAction}
      hasActionButton={true}
      actionButtonText={currentSlide < slides.length - 1 ? "Next" : "Done"}
      hasSecondaryActionButton={currentSlide > 0}
      secondaryActionButtonText="Back"
      onSecondaryAction={prevSlide}
    >
      <Box>
        <Typography className={classes.subheading}>
          {currentSlide < slides.length - 1
            ? subheading
            : slides[currentSlide].subheading}
        </Typography>
        <Typography className={classes.content}>
          {slides[currentSlide].content}
        </Typography>
        {slides[currentSlide].image && (
          <img
            src={slides[currentSlide].image}
            alt="Feature preview"
            className={classes.image}
          />
        )}

        {currentSlide === slides.length - 1 && (
          <Box>
            <EMPCheckbox
              checked={hidePopup}
              handleChange={(checked) => setHidePopup(checked)}
              name="hidePopup"
              label="Do not show this again"
              id="hidePopup"
            />
          </Box>
        )}
      </Box>
    </EMPDialog>
  );
};

export default withTranslation("emp")(SplashScreen);
